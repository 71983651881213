import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '@src/app/core/services/auth.service';
import { selectAuthState } from '@src/app/features/auth/store/auth.selectors';
import { of, skipWhile, switchMap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { LocalStorageService } from '../services';
import { User } from '@src/app/features/auth/types/auth.type';
import { ProfileStatus } from '../interfaces/profile-status.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  authService = inject(AuthService);
  user!: User;
    
  /**
   * Initializes a new instance of the class.
   *
   * @param {Router} router - The router service.
   * @param {Object} platformId - The platform ID.
   */
  constructor(
    private router: Router,
    private store: Store,
    private localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.user = this.localStorageService.get('user')
  }
  /**
   * Checks if the user can activate the route.
   *
   * @return {boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree>} Returns true if the user is logged in and the platform is a browser, otherwise returns false. If the platform is a browser and the user is not logged in, it sets the redirect URL and returns a UrlTree to the '/auth' route.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
  ): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    if (isPlatformBrowser(this.platformId)) {
      return this.store.select(selectAuthState).pipe(
        skipWhile(state => !state),
        switchMap((state): Observable<boolean> => {
          if (this.user && this.user.profileStatus === ProfileStatus.NEEDS_REWORK) {
            // this.router.navigate(['/edit-profile'])
            // this.router.navigate(['/error/no-access'])
            return of(false);
            // return of(true);
          } 
          else {
            return of(true);
          }
        }),
      );
    }
    return false;
  }
}

